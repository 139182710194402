export const APP_SETTINGS = {
  SUBSCRIPTION_KEY: 'ec4e597939d74001a84ff5805f7f0312',
  BASE_URL: document.getElementsByTagName('base')[0].href,
  BASE_I18N_URL: './assets/i18n/',
  COMPANY_NAME: 'Kata',
  VERSION_SYSTEM: '2024.5.0',
  FILE_TYPES: {
    JSON: '.json'
  },
  LANGUAGE: {
    DEFAULT_LANGUAGE: 'es',
    DEFAULT_DATE_TYPE: 'es-MX'
  },
  REGEX: {
    IS_URL: new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/),
    IS_EMPTY_TEXT: new RegExp(/^\s*$/)
  },
  LOGIN_SERVICES: {
    GOOGLE: {
      CLIENT_ID: '762924788643-2sui1g902m5u3dkmlae0euag9kakdtvp.apps.googleusercontent.com',
      ISSUER: 'https://accounts.google.com',
      SCOPE: 'openid profile email',
      DOCUMENT_VALIDATION: false
    }
  },
  LOCAL_STORAGE_IDS: {
    PROFILE: 3
  },
  MAPS: {
    GOOGLE_MAPS: {
      API_KEY: 'AIzaSyD02ns6eMaOXvRjB6tMzTWAoi9GJpZpsMQ'
    }
  },
  IMAGES: {
    LOGIN_SCREEN: './assets/img/login-screen.jpg',
    NO_IMAGE: './assets/img/no-image.svg',
    PRIMARY_COMPANY_LOGO: './assets/img/primary-company-logo.png',
    SECONDARY_COMPANY_LOGO: './assets/img/secondary-company-logo.png',
    ICON_CARPETA_ASIGNACION: './assets/img/icon_carpeta_asignación.svg',
    OTHER: {
      FORMIIK_MOBILE: './assets/img/other/formiik-mobile.svg'
    },
    ICONS: {
      COMMENT_BASELINE: './assets/img/icons/baseline-comment-24px.svg',
      COMMENT_OUTLINE: './assets/img/icons/outline-comment-24px.svg'
    }
  },
  CHANNELS: 'f77610c6-7892-4b12-aec4-6f261b81cf6f',
  UNIQUE_KEY: '3f1a2dd8-758b-4243-b55a-fd5c80b9cc52',
  HAS_ORIGINATIONS: 'a8f62555-278b-400a-ad60-4ff78d2017bb',
  GATHER_API: {
    REQUEST_TIME_AWAIT: 1000,
    CONTROLLERS: {
      HIERARCHY: 'hierarchy',
      ADMINISTRATIVE_CATALOGS: 'administrativeCatalogs',
      CATALOGS: 'catalogs',
      INSTANCES: 'instances',
      GEOFENCES: 'geofences',
      GEOFENCE: 'Geofence',
      QUERYABLE: 'queryable',
      FIND: 'find',
      AGGREGATE: 'aggregate',
      AGENTS: 'agents',
      UPLOAD_CATALOG: 'UploadCatalog',
      NOTIFICATION: 'Notification/Internals',
      FILES_CONTROLLER: 'files',
      BULK_UPLOAD: 'InstancesBulkOperations/upload/csv'
    },
    LOGIN: {
      CHANGE_PASSWORD: 'User/ChangePassword'
    },
    CATALOGS: {
      ACTIVE_AGENTS: 'Agents',
      DATA_TYPES: 'DataTypes',
      INACTIVE_AGENTS: 'agentesInactivos',
      SUSPENDED_AGENTS: 'agentesSuspendidos',
      LABELS: 'etiquetas',
      MEASURES: 'metricas',
      MEASURES_CATEGORY: 'categoriasMetrica',
      ROLES: 'roles',
      ALL_ROLES: 'Roles',
      VARIABLES: 'variables',
      VARIABLES_GROUP: 'grupovariables',
      DATA: 'Query/BCCC77CA-41DB-413A-ACDB-8A795BE9E9C2',
      DATA_CLONE: 'DataTypes/clone',
      PRODUCTS: 'Query/F64661B4-C722-44A2-95D9-840271B7B419',
      UPDATE_LINEAR_FLOW: 'linearflows/update/',
      LINEAR_FLOW: 'linearflows/',
      BULK_LOAD: 'Log/GetMasiveLoadData',
      BULK_LOAD_ID: 'Log/GetMasiveLoadDataById',
      BULK_LOAD_INSTANCE: 'InstancesBulkOperations'
    },
    HIERARCHY: {
      ROOT: 'root',
      NODES: 'nodes',
      CHILD_NODE: 'childNode',
      SHARED_ROOT: 'sharedRoot',
      RESPONSIBLE: 'responsible',
      MEMBER: 'member',
      FOLDER: 'folder',
      SIBLING: 'siblingNode',
      SEARCHNAME: 'searchByName',
      SEARCHNODE: 'searchById',
      SEARCHFOLDERID: 'FolderHelper/GetNodeByFolderId'
    },
    INSIGHTS:{
      LOG: 'LogInsights'
    },
    QUERYABLES: {
      AGENTS_WITHOUT_TASKS: 'agentsWithoutTask'
    },
    AGENTS: {
      SYNC: 'Sync'
    },
    PRODUCTS: {
      PUBLISH: 'Products/Publish',
      REMOVE: 'Products/Retirement'
    }
  },
  VALIDATION_STATUS: {
    VALUE_INVALID: 3,
    VALUE_VALID: 0
  },
  VALIDATION_CONSTANTS: {
    EXTERNAL_CONTAINER_SEPARATOR: ':\\\\',
    FIELD_PARTS_LENGTH: 2,
    AGENTID_FIELD: 'agentId',
    REMANENT_IN_ROL_NAME: '"'
  },
  ADD_FUNCTION_VARIABLE: {
    FUNCTION: 'variablesHelper.getVariableValue'
  }
};
